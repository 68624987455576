<template>
  <div class="church-presentation-template">
    <page-header>
      <!-- <h1 class="d-none">Add/Edit Video Presentation</h1> -->
      <h1 class="d-none">{{ translations.tcAddEditVideoPresentation }}</h1>
    </page-header>

    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1>{{ translations.tcAddEditVideoPresentation }}</h1>
        <b-row>
          <b-col sm="6" class>
            <div class="d-flex mb-2">
              <i class="mr-3">
                <iMapPoint />
              </i>
              <h6>{{ facility.org_name }}</h6>
            </div>
            <div class="address mb-3">
              <span v-if="!!facility">{{ facility.address_line_1 }}</span>
              <br />
              <span v-if="!!facility.address_line_2">{{ facility.address_line_2 }}<br /></span>
              <span v-if="!!facility.address_line_3">{{ facility.address_line_3 }}<br /></span>
              <span v-if="!!facility">{{ facility.city }}, {{ facility.state_postal_code }}</span>
              <br />
              <span v-if="!!facility">{{ facility.address_cnt_name }}</span>
            </div>
            <div class="d-flex mb-3">
              <i class="mr-3">
                <iCalendar />
              </i>
              <p>{{ formattedMeetingDate }}</p>
            </div>
          </b-col>
          <b-col sm="6" class>
            <div class="d-flex mb-2">
              <span class="label font-style-1 mr-2">{{ translations.tcCoordinator }}:</span>
            </div>
            <div class="mb-3">
              <span style="width: 100%">{{ coordinator.formal_name }}</span>
              <br />
              <span style="width: 100%">{{ coordinator.phone }}</span>
              <br />
              <span style="width: 100%">{{ coordinator.email }}</span>
              <br />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6" class>
            <div class="d-flex">
              <span class="label font-style-1 mr-2">{{ translations.tcStatus }}:</span>
              <span class="value font-weight-bold lh44">
                {{ meetingStatus }}
              </span>
            </div>
          </b-col>
          <b-col sm="6" class>
            <div class="d-flex">
              <span class="label font-style-1 mr-2">{{ translations.tcPresenter }}:</span>
              <span class="value font-weight-bold lh44">
                {{ presenterName }}
              </span>
            </div>
          </b-col>
        </b-row>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <p>{{ meetingNote }}</p>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button
              v-if="iCanSee(secured_restocking_visit_edit_controls.edit_gideoncard_restocking_visit_button)"
              variant="primary"
              @click="handleEditClick"
              class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
              >{{ translations.tcEdit }}</b-button
            >
            <b-button
              v-if="iCanSee(secured_restocking_visit_delete_controls.delete_gideoncard_restocking_visit_button)"
              variant="secondary"
              @click="handleDeleteClick"
              class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0"
              >{{ translations.tcDelete }}</b-button
            >
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
              translations.tcCancel
            }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iMapPoint from '@/assets/svgs/iMapPoint.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'video-visit',
  mixins: [translationMixin],
  data() {
    return {
      formattedMeetingDate: '',
      meetingStatus: '',
      secured_restocking_visit_delete_controls: {
        delete_gideoncard_restocking_visit_button: 'f8d26c44-16fa-4a04-b621-b97872c782e4',
      },
      secured_restocking_visit_edit_controls: {
        edit_gideoncard_restocking_visit_button: '7d2ed082-cf7f-489c-b298-8fe90a60073c',
      },
      translations: {},
    }
  },
  methods: {
    ...mapActions({
      deleteVideoPresentation: 'card/deleteVideoPresentation',
      loadProfile: 'card/getProfile',
      retrieveMeetingTypes: 'card/retrieveMeetingTypes',
      retrieveVideoVisitDetails: 'card/retrieveVideoVisitDetails',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedFacilityKey: 'user/setSelectedFacilityKey',
      upsertVideoVisit: 'card/upsertVideoVisit',
    }),
    async handleEditClick() {
      await this.setSelectedFacilityKey(this.facility.gcf_org_key)
      this.$router.push('/programs/gc/events/card_video_presentation_add')
    },
    async handleCancelClick() {
      await this.setSelectedFacilityKey(this.facility.gcf_org_key)
      this.$router.push('/programs/gc/profile/card-video-visits-list')
    },
    async handleDeleteClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: this.translations.tcCancel,
        confirmButtonText: this.translations.tcOk,
        text: this.translations.tcVideoPresentationDeleteConfirmation,
      })
        .then((result) => {
          if (result.value) {
            Promise.all([
              this.setLoadingStatus(true),
              this.deleteVideoPresentation({ mtg_key: this.selectedVisitKey, ind_key: this.userId }),
              this.setLoadingStatus(false),
            ])
          }
        })
        .then(() => {
          this.$router.push('/programs/gc/profile/card-video-visits-list')
        })
    },
    setFormattedDate() {
      if (!this.translations || !this.videoVisitDetails || !this.videoVisitDetails.meeting)
        this.formattedMeetingDate = ''

      this.formattedMeetingDate = this.returnFormattedDate(this.videoVisitDetails.meeting.mtg_start_date)
    },
    setMeetingStatus() {
      if (!this.translations || !this.videoVisitDetails || !this.videoVisitDetails.meeting) this.meetingStatus = ''

      const originalMeetingStatus = this.videoVisitDetails.meeting.vmts_trn_txt || ''
      const lookup = this.convertValForTranslation(originalMeetingStatus)

      return (this.meetingStatus = this.translations.hasOwnProperty(lookup)
        ? this.translations[lookup]
        : this.videoVisitDetails.meeting)
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        await this.getViewTranslations(),
        await this.retrieveMeetingTypes(),
        !this.selectedVisitKey ? null : await this.retrieveVideoVisitDetails(this.selectedVisitKey),
      ]).then(() => {
        this.setSelectedFacilityKey(this.videoVisitDetails.facility.gcf_org_key)
        this.loadProfile(this.userSelectedFacilityKey)
        this.setFormattedDate()
        this.setMeetingStatus()
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  computed: {
    ...mapGetters({
      iCanSee: 'user/iCanSee',
      meetingTypes: 'card/getMeetingTypes',
      prefCulture: 'user/userPreferredCulture',
      profileSummary: 'card/getFacilitySummary',
      selectedVisitKey: 'churchMinistry/selected_visit_key',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userSelectedFacilityKey: 'user/userSelectedFacilityKey',
      userSelectedMeetingKey: 'user/userSelectedMeetingKey',
      videoVisitDetails: 'card/getVideoVisitDetails',
    }),
    facility() {
      return (
        this.videoVisitDetails.facility || {
          contact_name: '',
          org_name: '',
          address_line_1: '',
        }
      )
    },
    coordinator() {
      return !!this.videoVisitDetails && !!this.videoVisitDetails.coordinator
        ? {
            formal_name: this.videoVisitDetails.coordinator.formal_name,
            email: this.videoVisitDetails.coordinator.email,
            phone: this.videoVisitDetails.coordinator.phone,
          }
        : ''
    },
    presenterName() {
      return !!this.videoVisitDetails && !!this.videoVisitDetails.presentationIndividual
        ? this.videoVisitDetails.presentationIndividual.formal_name
        : ''
    },
    meetingNote() {
      return !!this.videoVisitDetails && !!this.videoVisitDetails.meeting ? this.videoVisitDetails.meeting.mtg_note : ''
    },
  },
  components: {
    iCalendar: iCalendar,
    iMapPoint: iMapPoint,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}
#meeting-status {
  font-size: 16px;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
}
.section-1 {
  .d-flex {
    align-items: center;
  }
  i {
    width: 22px;
    font-size: 0;
    text-align: center;
    svg {
      display: inline-block;
    }
  }
  h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
  }
  .address {
    padding-left: 46px;
  }
}
.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

.col-left-input {
  width: 291px !important;
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}
</style>
